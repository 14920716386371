




















import { defineComponent } from "@vue/composition-api";
import AnggotaFM from "../models/anggotaForm";
import KonsulenFM from "../models/konsulenForm";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "KonsulenForm",
  components: {
    AnggotaForm: () => import("@/apps/anggota/views/AnggotaForm.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  props: {
    headerText: { type: String, required: true },
  },
  setup() {
    const requiredFields = ["nama", "gender", "email"];
    const nonRequiredFields = ["inisial", "noHp", "alamat", "catatan"];
    const anggotaForm = new AnggotaFM(requiredFields, nonRequiredFields);
    const form = new KonsulenFM(["subdivisi"], ["anggota"], anggotaForm);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const konsulen = composition.instance;

    return {
      // Data
      form,
      konsulen,

      // Composition
      ...composition,
    };
  },
});
