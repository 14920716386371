import { API } from "@/apps/core/api";
import { DokterBase } from "./anggota";
import ViewModel from "@/apps/core/models/viewModel";

export class Konsulen extends DokterBase {
  // mulai: string | null = null;
  subdivisi: Record<string, any> | null = null;
}

export class KonsulenAPI extends API {
  static modelPath = "/anggota/konsulen/";

  constructor() {
    super(KonsulenAPI.modelPath);
  }
}

export default class KonsulenVM extends ViewModel {
  constructor() {
    super(new KonsulenAPI(), new Konsulen());
  }
}
